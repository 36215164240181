import { Col, List, Row, Spin, Typography } from 'antd';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useState } from 'react';
import LottoCard from '../../components/lotto-card/lotto-card';
import MainLayout from "../../components/main-layout/main-layout";
import "./main.css";
import 'moment/locale/th';
import { getPeriods, getThaiLotto, logout } from '../../services/services';
import { PeriodType } from '../../types/period.type';
import { useHistory } from 'react-router-dom';
import Notification from '../../components/notification/notification';

const { Title, Text, Link } = Typography;

const Main: FC = () => {
    let history = useHistory();
    const token = localStorage.getItem("token");
    moment.locale('th');
    const [loading, setLoading] = useState(true);
    const [periodsData, setPeriodsData] = useState(null);
    const [thaiLotto, setThaiLotto] = useState(null);
    const [showThaiLotto, setShowThaiLotto] = useState(false);
    const today = moment();

    const getPeriodsData = useCallback(async () => {
        const response = await getPeriods();
        setPeriodsData(response.data);
    }, []);

    const getThaiLottoData = useCallback(async () => {
        const response = await getThaiLotto();
        const lotto = response.data.data[0];

        if (today.toDate().getTime() < moment(lotto.dateClose).toDate().getTime()) {
            setShowThaiLotto(true);
        }

        setThaiLotto(response.data);
    }, []);

    useEffect(() => {
        if (!token) return history.push('/login');
        getPeriodsData().then(() => setLoading(false)).catch((v) => {
            setLoading(false);
            logout();
            return history.push('/login');
        });

        // getThaiLottoData().then(v => {
        //     setLoading(false);
        // }).catch((v) => {
        //     setLoading(false);
        //     logout();
        //     return history.push('/login');
        // });

    }, [getPeriodsData]);

    const data = [
        {
            dateTime: moment().format("DD MMMM YYYY HH:mm"),
            description: 'กรุณาศึกษากติกาและคู่มือการใช้งานระบบให้ละเอียด ตามลิ้งนี้'
        },
        {
            dateTime: moment().format("DD MMMM YYYY HH:mm"),
            description: 'หากต้องการสอบถามข้อมูลต่างๆ กรุณาติดต่อสายบนของท่าน'
        }
    ];

    const goLink = (period: PeriodType) => {
        if (period.status === 'inactive') {
            Notification('error', period.lottoMaster?.name + 'ปิดรับแล้ว', 'แจ้งเตือนจากระบบ');
            return '#'
        }

        return history.push({
            pathname: '/bet',
            state: {
                period
            }
        })
    }

    const renderPeriods = (lottoTypeId: number) => {
        if (!periodsData) return <></>;
        const data: PeriodType[] = [...periodsData['data']];

        return data.map((period: PeriodType, index: number) => {
            if (period.lottoMaster.lottoType.id === lottoTypeId) {
                return <Col span={6} md={6} sm={6} xs={24}>
                    <Link onClick={() => goLink(period)}>
                        <LottoCard key={index} period={period} />
                    </Link>
                </Col>
            }
        });
    }

    const renderThaiLotto = () => {
        if (!thaiLotto) return <></>;
        const data: PeriodType[] = [...thaiLotto['data']];

        return data.map((period: PeriodType, index: number) => {
            return <Col md={24} sm={24} xs={24} lg={6} xl={6}>
                <Link onClick={() => goLink(period)}>
                    <LottoCard key={index} period={period} />
                </Link>
            </Col>
        });
    }

    return (
        <Spin spinning={loading}>
            <MainLayout menu="bet">
                <div className="announce-section">
                    <div className="announce-header">
                        <Title level={5} style={{ color: '#fff' }}>ประกาศบริษัท</Title>
                    </div>
                    <div className="announce-content">
                        <List
                            itemLayout="horizontal"
                            dataSource={data}
                            renderItem={item => (
                                <List.Item style={{ padding: 4 }}>
                                    <List.Item.Meta
                                        title={<Text strong style={{ color: '#007EF5' }}>{item.dateTime}</Text>}
                                    />
                                    {item.description}
                                </List.Item>
                            )}
                        />
                    </div>
                </div>

                {showThaiLotto ?
                    <div className="lotto-section">
                        <Title level={5}>หวยไทย</Title>
                        <Row gutter={[8, 8]}>
                            {thaiLotto ? renderThaiLotto() : <></>}
                        </Row>
                    </div> : <></>}

                <div className="lotto-section">
                    <Title level={5}>หวยลาว</Title>
                    <Row gutter={[8, 8]}>
                        {periodsData ? renderPeriods(1) : <></>}
                    </Row>
                </div>

                {/* <div className="lotto-section">
                    <Title level={5}>หวยหุ้น Go</Title>
                    <Row gutter={[8, 8]}>
                        {periodsData ? renderPeriods(3) : <></>}
                    </Row>
                </div> */}
            </MainLayout>
        </Spin>
    );


}

export default Main;